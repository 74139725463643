import React, {useCallback, useState} from 'react';
import { LegacyStack, ChoiceList, Text } from "@shopify/polaris";

const CartOfferTypeSelector = ({offerType, changeOfferType, shopBrainType}) => {
  const [selected, setSelected] = useState(offerType);
  const [brainType, setBrainType] = useState(shopBrainType);

  const handleChange = useCallback((value) => {
    setSelected(value[0]);
    changeOfferType(value[0]);
  }, []);

  const handleShopBrainTypeChange = useCallback((value) => {
    setBrainType(value[0]);
    changeOfferType('ai', value[0]);
  }, []);

  return (
    <div>
      <Text variant="headingSm" as="h3">Product selection</Text>
      <ChoiceList
        title="Product selection<"
        titleHidden
        choices={[
          {
            label: 'Manual',
            value: 'manual',
            helpText: 'Manually choose the products to show as an upsell',
          },
          {
            label: 'ShopBrain',
            value: 'ai',
            helpText: (
              <div>
                <div>Let our algorithm show the best product recommendations</div>
                {selected === 'ai' && (
                  <div style={{ marginTop: '10px' }}>
                    <ChoiceList
                    choices={[
                      {
                        label: 'Complementary products',
                        value: 'complementary',
                      },
                      {
                        label: 'Related products',
                        value: 'related',
                      },
                    ]}
                    selected={[brainType]}
                    onChange={handleShopBrainTypeChange}
                    horizontal
                  />
                  </div>
                )}
              </div>
            )
          },
        ]}
        selected={selected}
        onChange={handleChange}
      />
    </div>
  );
};

export default CartOfferTypeSelector;